<template>
  <v-app>
    <v-stepper v-model="stepCount">
      <v-stepper-header>
        <v-stepper-step
          :complete="stepCount > getStepVal('targetDataFileUpload')"
          :step="getStepVal('targetDataFileUpload')"
        >
          Upload Target Data
        </v-stepper-step>
        <v-divider />
        <v-stepper-step
          :complete="stepCount > getStepVal('importTargetFile')"
          :step="getStepVal('importTargetFile')"
        >
          Import Target Data
        </v-stepper-step>
        <v-divider />
        <v-stepper-step
          :complete="stepCount > getStepVal('targetDataSummary')"
          :step="getStepVal('targetDataSummary')"
        >
          Target Data Import Summary
        </v-stepper-step>
        <v-divider />
      </v-stepper-header>
      <v-stepper-items>
        <v-stepper-content
          class="pa-3"
          :step="getStepVal('targetDataFileUpload')"
        >
          <TargetDataFileUpload
            v-if="getStepVal('targetDataFileUpload') == stepCount"
            ref="targetDataFileUpload"
            :key="getStepKey('targetDataFileUpload')"
            :step-val="getStepVal('targetDataFileUpload')"
            :suppression-code="$route.query.sc"
            :step-count="stepCount"
            @row0="getRow"
            @fileArray="getFileArray"
            @fileData="getFileData"
            @selectedMatch="getSelectedMatch"
            @ama-selected="getAmaSelected"
            @cancelStep="cancelStep"
            @advanceFromStep="advanceFromStep"
          />
        </v-stepper-content>
        <v-stepper-content
          class="pa-3"
          :step="getStepVal('importTargetFile')"
        >
          <ImportTargetFile
            v-if="getStepVal('importTargetFile') == stepCount"
            ref="importTargetFile"
            :key="getStepKey('importTargetFile')"
            :step-val="getStepVal('importTargetFile')"
            :row0="row0"
            :file-array="fileArray"
            :file-data="fileData"
            :selected-match="selectedMatch"
            :ama-selected="amaSelected"
            @cancelStep="cancelStep"
            @advanceFromStep="advanceFromStep"
          />
        </v-stepper-content>
        <v-stepper-content
          class="pa-3"
          :step="getStepVal('targetDataSummary')"
        >
          <TargetDataSummary
            v-if="getStepVal('targetDataSummary') == stepCount"
            ref="targetDataSummary"
            :key="getStepKey('targetDataSummary')"
            :step-val="getStepVal('targetDataSummary')"
            :ama-selected="amaSelected"
            @cancelStep="cancelStep"
            @advanceFromStep="advanceFromStep"
          />
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </v-app>
</template>
<script>
import { mapGetters } from 'vuex'
import mutationTypes from '@/store/mutation-types'

export default {
  name: 'TargetDataImport',

  components: {
    TargetDataFileUpload: () => import('@/views/pages/target-data/TargetDataFileUpload'),
    ImportTargetFile: () => import('@/views/pages/target-data/ImportTargetFile'),
    TargetDataSummary: () => import('@/views/pages/target-data/TargetDataSummary')
  },
  data () {
    return {
      stepCount: 1,
      routes: [
        {
          path: 'targetDataFileUpload',
          ref: 'targetDataFileUpload',
          stepVal: 1,
          key: 0
        },
        {
          path: 'importTargetFile',
          ref: 'importTargetFile',
          stepVal: 2,
          key: 0
        },
        {
          path: 'targetDataSummary',
          ref: 'targetDataSummary',
          stepVal: 3,
          key: 0
        }
      ],
      selectedFileType: [],
      selectedMatch: [],
      valid: false,
      brandName: '',
      isSingleColumn: false,
      targetDataFile: null,
      fileData: '',
      row: '',
      row0: '',
      fileArray: [],
      targetFileRules: [
        value =>
          !value ||
            value.size < 11000000 ||
            `${vm.contactType} Contact size should be less than 11 MB`
      ],
      resultsLoading: false,
      uploadingFiles: false,
      uploadError: '',
      fileTypes: [
        { value: 'AMA', description: 'AMA' },
        { value: 'APN', description: 'APN' },
        { value: 'AAPA', description: 'AAPA' }
      ],
      matchTypes: [
        { value: 'ME', description: 'ME Numbers:10 or 11-digit (AMA)' },
        { value: 'MIX', description: 'MMS ID Numbers (AMA)' },
        { value: 'Name', description: 'Name/Zip Match' },
        { value: 'NPI', description: 'NPI Number' }
      ],
      amaSelected: 0,
      apnSelected: 0,
      aapaSelected: 0,
      meSelected: 0,
      match11: 0,
      mixSelected: 0,
      nameSelected: 0,
      npiSelected: 0
    }
  },

  computed: {
    ...mapGetters(['viewAsCustomer', 'simulatedCustomerCrmId']),
    ...mapGetters('auth', [
      'isMMSRole',
      'getTenantId',
      'getTenantCrmId'
    ]),
    showViewAsCustomer () {
      return this.$store.getters['auth/isMMSRole']()
    },
    isLoading () {
      return this.loadingCount > 0
    }
  },

  methods: {
    clearErrors () {
      this.uploadError = ''
    },
    getStepVal (stepRef) {
      return this.routes.find(x => x.ref === stepRef).stepVal
    },
    getStepKey (stepRef) {
      return this.routes.find(x => x.ref === stepRef).key
    },
    async getRow (row0) {
      this.row0 = row0
    },
    async getFileArray (fileArray) {
      this.fileArray = fileArray
    },
    async getFileData (fileData) {
      this.fileData = fileData
    },
    async getSelectedMatch (selectedMatch) {
      this.selectedMatch = selectedMatch
    },
    async getAmaSelected (amaSelected) {
      this.amaSelected = amaSelected
    },
    async cancelStep (stepNum) {
      this.stepCount = stepNum - 1
      // await this.setMailingStepValue({ prop: this.getStep(this.stepCount).api, flag: 'false' })
    },
    async advanceFromStep (stepNum) {
      this.stepCount = stepNum + 1
    }
  }

}
</script>
